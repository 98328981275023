<template>
  <div
    class="ScenarioView"
    :class="[`style-${style}`]">
    <StepBackground
      v-if="step"
      :image="step.image" />
    <div
      v-if="phase === 'task'"
      class="Task">
      <div class="Task__spacer1" />
      <LifeskillNumberTag
        class="Task__lifeskillNumberTag"
        :lifeskill-id="lifeskillId" />

      <div class="Task__title">
        Scenario
      </div>
      <div class="Task__subtitle">
        <span>{{ $t('fromStep', { number: step.number }) }}: </span>
        <span v-html="step.title">{{ step.title }}</span>
      </div>
      <div class="Task__spacer2" />

      <div
        v-if="scenario"
        class="Task__text"
        v-html="scenario.scenario.text" />
      <div
        v-if="!scenario"
        class="Task__text">
        Loading...
      </div>

      <div class="Task__spacer3" />

      <div class="footer">
        <StandardButton
          class="doneButton"
          label="Ok, jeg er ferdig"
          icon="arrow-right"
          @click="markAsDone()" />
      </div>
    </div>

    <div
      v-if="phase === 'resources'"
      class="Resources">
      <div class="Task__spacer1" />

      <LifeskillNumberTag
        class="Task__lifeskillNumberTag"
        :lifeskill-id="lifeskillId" />

      <div class="Resources__title">
        Bra innsats!
      </div>
      <div class="Task__spacer2" />

      <div class="Resources__text">
        <div class="Quote">
          <div
            class="Quote__text"
            v-html="quote.text" />
          <div class="Quote__source">
            {{ quote.source }}
          </div>
        </div>

        <div class="dailyEffortReminder">
          Det er den daglige innsatsen som teller for å omdanne en ferdighet til en vane, så husk på å ta et
          nytt scenario i morgen.
        </div>
      </div>

      <div class="Task__spacer3" />

      <div class="footer">
        <StandardButton
          class="exitButton"
          label="Lukk"
          icon="arrow-right"
          @click="exit()" />
      </div>
    </div>

    <div class="header">
      <div class="headerWidthWrapper">
        <div
          v-if="phase === 'resources'"
          class="headerBackButton --global-clickable"
          @click="showQuestion()">
          <svgicon
            class="svg-icon"
            icon="arrow-left" />
          Tilbake
        </div>
        <div />
        <div
          class="headerExitButton --global-clickable"
          @click="exit()">
          <svgicon
            class="svg-icon"
            icon="close" />
        </div>
      </div>
    </div>
  </div>
</template>

<translations>
  title: 'Scenario {number} from step {stepNumber}'
  title_no: 'Scenario {number} fra steg {stepNumber}'

  fromStep: From step {number}
  fromStep_no: Fra steg {number}
</translations>

<script>
import store from '@/store';
import StandardButton from '@/components/StandardButton';
import StepBackground from '@/modules/course/components/StepBackground';
import LifeskillNumberTag from '@/modules/dashboard/components/LifeskillNumberTag';

const quotes = [
    {
        text: 'Laziness is nothing more than the habit of resting before you get tired.',
        source: 'Jules Renard'
    },
    {
        text: 'Winning is a habit. Unfortunately so is losing.',
        source: 'Vince Lombardi'
    },
    {
        text: 'Quality is not an act, it is a habit.',
        source: 'Aristotle'
    },
    {
        text: 'We are what we repeatedly do. Excellence, then, is not an act, but a habit.',
        source: 'Aristotle'
    },
    {
        text: 'Motivation is what gets you started. Habit is what keeps you going.',
        source: 'Jim Ryun'
    },
    {
        text: 'Winners make a habit of manufacturing their own positive expectations in advance of the event.',
        source: 'Brian Tracy'
    },
    {
        text: 'Once you learn to quit, it becomes a habit.',
        source: 'Vince Lombardi'
    },
    {
        text: 'Habit is a cable; we weave a thread of it each day, and at last we cannot break it.',
        source: 'Horace Mann'
    },
    {
        text: 'Ninety-nine percent of the failures come from people who have the habit of making excuses.',
        source: 'George Washington Carver'
    },
    {
        text: 'Chains of habit are too light to be felt until they are too heavy to be broken.',
        source: 'Warren Buffett'
    },
    {
        text: 'Habit is stronger than reason.',
        source: 'George Santayana'
    },
    {
        text: 'A nail is driven out by another nail. Habit is overcome by habit.',
        source: 'Desiderius Erasmus'
    },
    {
        text: 'Great is the power of habit. It teaches us to bear fatigue and to despise wounds and pain.',
        source: 'Marcus Tullius Cicero'
    },
    {
        text: "You need to play with supreme confidence, or else you'll lose again, and then losing becomes a habit.",
        source: 'Joe Paterno'
    },
    {
        text: 'Once you break a habit into its components, you can fiddle with the gears.',
        source: 'Charles Duhigg'
    }
];

export default {
    components: { StandardButton, LifeskillNumberTag, StepBackground },
    props: {
        id: {
          String,
          required: true,
        },
        lifeskillId: {
            type: String,
            required: true
        },
        stepId: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            style: 'styleA',
            phase: 'task',
        };
    },
    computed: {
      courseId() {
        return this.step.courseId;
      },

      step() {
        return this.$store.getters['moduleCourse/getStepById'](this.stepId);
      },

      scenario() {
        return this.$store.getters['scenario/byScenarioId'](this.id);
      },

      quote() {
        return quotes[Math.floor(Math.random() * quotes.length)];
      },
    },

    async mounted() {
      await store.dispatch('scenario/loadData', this.courseId);
      await store.dispatch('scenario/loadProgress');
    },

    methods: {
        showQuestion() {
            this.phase = 'task';
        },
        markAsDone() {
            this.phase = 'resources';
            this.$store.dispatch('scenario/markAsDone', {
                stepId: this.stepId,
                scenarioId: this.id,
                courseId: this.courseId,
                lifeskill: this.lifeskillId,
                stepGroupId: this.step.stepGroupId,
            });
        },
        exit() {
            this.$router.push(
                this.returnPath || {
                    name: 'DashboardLifeskillView',
                    params: {
                        lifeskillId: this.lifeskillId,
                        tabName: 'practice'
                    }
                }
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.ScenarioView {
    position: relative;
    // height: 100%;
    display: flex;
    flex-direction: column;

    background-color: black;

    background-size: cover;
    background-position: center;
}

// -----------------------------------------
// Body styling
// -----------------------------------------

.body {
    flex-grow: 1;
    overflow-y: scroll;
    padding-top: 0;
    height: 100%;
}

.Scenario {
    min-height: 100%;
}

@include media('<mobile') {
    .headerBackButton {
        display: none; // Because we use a global menu for mobile
    }
}
@include media('>=mobile') {
}

// -----------------------------------------
// Header
// -----------------------------------------

.header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    color: rgba(white, 0.8);
}
.headerWidthWrapper {
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1200px;
}
.headerExitButton {
    $size: 3em;
    width: $size;
    height: $size;
    display: flex;
    justify-content: center;
    align-items: center;
    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: white;
    }
}
.headerBackButton {
    $size: 3em;
    padding-left: 1em;
    height: $size;
    display: flex;
    justify-content: center;
    align-items: center;
    .svg-icon {
        $size: 1em;
        width: $size;
        height: $size;
        fill: white;
        margin-right: 0.5em;
    }
}

// -----------------------------------------
// Footer
// -----------------------------------------

.footer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-end;
}

// -----------------------------------------
// Background
// -----------------------------------------

.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-size: cover;
    background-position: center;

    opacity: 0.5;
}

.DashboardScenarioView.style-styleB {
    .background {
        opacity: 0.2;
    }
}

.DashboardScenarioView.style-styleC {
    .background {
        opacity: 0.2;
        filter: grayscale(100%);
    }
}

// -----------------------------------------
// Task
// -----------------------------------------

.Task {
    min-height: 100%;
    color: white;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 1em;

    overflow-y: auto;
}
.Task__lifeskillNumberTag {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 3em;
    margin-right: 2em;
    flex-shrink: 0;
}
.Task__title {
    font-size: 200%;
}
.Task__subtitle {
    margin-top: 0.5em;
    color: rgba(white, 0.8);
}
.Task__text {
    max-width: 30em;
    line-height: 1.5em;
    text-align: left;
    font-size: 140%;

    ul {
        padding-left: 2em;
        list-style: disc;

        li {
            margin-bottom: 0.5em;
        }
    }
}

.Task__spacer1 {
    flex: 1;
}
.Task__spacer2 {
    height: 3em;
    min-height: 1em;
    max-height: 3em;
}
.Task__spacer3 {
    flex: 1;
}

.doneButton {
    background-color: transparent !important;
}

// -----------------------------------------
// Resources
// -----------------------------------------

.Resources {
    padding: 2em;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .footer {
        justify-content: center;
    }
}
.Resources__spacer {
    flex: 1;
}
.Resources__title {
    font-size: 160%;
    color: rgba(white, 0.9);
}
.Resources__list {
    width: 100%;

    max-width: 30em;
    margin: auto;
    margin-top: 3em;
}

.Resource {
    background-color: white;
    padding: 0.5em;
    margin: 1em 0;
    padding-right: 1em;
    border-radius: 4px;

    display: flex;
    align-items: center;
}
.Resource__image {
    width: 4em * 1.3;
    background-color: gray;
    flex-shrink: 0;
    height: 4em;

    background-size: cover;
    background-position: center;
}
.Resource__info {
    flex: 1;
    padding: 0 1em;
}
.Resource__title {
    color: rgba(black, 0.8);
}
.Resource__subtitle {
    font-size: 80%;
    color: rgba(black, 0.6);
    margin-top: 0.5em;
}
.Resource__button {
    font-size: 80%;
}

.Resources__backButton {
    color: rgba(white, 0.5);
    margin-bottom: 1em;
    width: 100%;
    max-width: 30em;
    text-align: left;
}

.Resources__text {
    max-width: 30em;
    line-height: 1.5em;
    text-align: left;
    font-size: 140%;
}

.Quote {
    color: rgba(white, 0.9);
}
.Quote__text {
    font-style: italic;
}
.Quote__source {
    text-align: right;
    &::before {
        display: inline-block;
        margin-right: 0.3em;
        content: '\2014 ';
    }
}

.dailyEffortReminder {
    color: rgba(white, 0.9);
    margin-top: 2em;
}

.exitButton {
    .common-MultipurposeButton__icon {
        display: none;
    }
    .common-MultipurposeButton__label {
        padding-left: 2em;
        padding-right: 2em;
    }
}

@include media('<mobile') {
    .Task__text,
    .Resources__text {
        font-size: 120%;
    }
}
</style>
